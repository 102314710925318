import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { class: "content" }

import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';
import LogoIcon from '@/components/icons/logo.vue';
import QuestionsView from '@/components/questions/Questions.vue';
import QuestionSkeleton from '@/components/questions/Question.skeleton.vue';
import CompleteView from '@/components/Complete.vue';
import ErrorView from '@/components/Error.vue';
import { useSurvey } from '@/hooks/survey';
import LangSelector from '@/components/LangSelector/LangSelector.vue';
import { PaxProgress, Spin as PaxSpin } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyView',
  setup(__props) {

const { theme, switchTheme } = useTheme();
switchTheme();
const themeStyles = computed(() => theme.value?.styles);

const {
  surveyModel,
  isLoading,
  isFirstLoading,
  error,
  progress,
  isComplete,
  updateAnswer,
  sendComplete,
  allowLanguages,
  selectedLanguage
} = useSurvey();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['root', themeStyles.value?.app?.root])
    }, [
      _createElementVNode("header", {
        class: _normalizeClass(['header', themeStyles.value?.header?.header])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(['header-icon', themeStyles.value?.header?.icon])
          }, [
            (_unref(theme)?.icons?.logo)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).icons.logo), { key: 0 }))
              : (_openBlock(), _createBlock(LogoIcon, { key: 1 }))
          ], 2),
          (_unref(selectedLanguage))
            ? (_openBlock(), _createBlock(LangSelector, {
                key: 0,
                "selected-language": _unref(selectedLanguage),
                "onUpdate:selectedLanguage": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedLanguage) ? (selectedLanguage).value = $event : null)),
                languages: _unref(allowLanguages)
              }, null, 8, ["selected-language", "languages"]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (!(_unref(error) || _unref(isComplete) || _unref(isFirstLoading)))
        ? (_openBlock(), _createBlock(_unref(PaxProgress), {
            key: 0,
            percent: _unref(progress),
            class: "progress-bar"
          }, null, 8, ["percent"]))
        : _createCommentVNode("", true),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_unref(error))
              ? (_openBlock(), _createBlock(ErrorView, {
                  key: 0,
                  error: _unref(error)
                }, null, 8, ["error"]))
              : (_unref(isComplete))
                ? (_openBlock(), _createBlock(CompleteView, { key: 1 }))
                : (_unref(surveyModel))
                  ? (_openBlock(), _createBlock(QuestionsView, {
                      key: 2,
                      questions: _unref(surveyModel).questions ?? [],
                      "is-loading": _unref(isLoading),
                      "onUpdate:answer": _unref(updateAnswer),
                      onSend: _unref(sendComplete),
                      onProgress: _cache[1] || (_cache[1] = (value) => (progress.value = value))
                    }, null, 8, ["questions", "is-loading", "onUpdate:answer", "onSend"]))
                  : (_openBlock(), _createBlock(QuestionSkeleton, { key: 3 }))
          ]),
          _: 1
        })
      ])
    ], 2),
    (_unref(isLoading) && _unref(surveyModel))
      ? (_openBlock(), _createBlock(_unref(PaxSpin), {
          key: 0,
          class: "loader"
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})