import { Composer, createI18n, I18n, I18nOptions } from 'vue-i18n';
import { LocaleValue, MessagesMap } from '@/i18n/i18n.types';
import { OwnerType } from '@/api/response.types';
import { LOGGER } from '@/plugins';
import { ILogger } from '@/types';
import { inject } from 'vue';

export function setupI18n(options: I18nOptions = { locale: 'en' }): I18n {
  const i18n = createI18n(options) as I18n;

  setI18nLanguage(i18n, options.locale!);

  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: LocaleValue) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    (i18n.global as Composer).locale.value = locale;
  }
}

export const loadLocaleMessages = async (local: LocaleValue, themeName?: OwnerType) => {
  const logger = inject<ILogger>(LOGGER);
  let messages: MessagesMap = {};

  const fetchJSON = async (url: string): Promise<MessagesMap> => {
    const response = await fetch(url);

    if (response.ok) {
      return response.json();
    }

    throw new Error(response.status.toString());
  };

  try {
    const defaultTranslation = await fetchJSON(`translation/${local}.translation.json`);

    if (defaultTranslation) {
      messages = defaultTranslation;
    }
  } catch {
    logger?.log(`Not found translation file ${local}.translation.json for ${themeName}`);
  }

  if (themeName) {
    try {
      const themeTranslations = await fetchJSON(`themes/${themeName}/translation/${local}.translation.json`);

      if (themeTranslations) {
        messages = { ...messages, ...themeTranslations };
      }
    } catch {
      logger?.log(`Not found theme translation file ${local}.translation.json for ${themeName}`);
    }
  }

  return messages;
};
