<template>
  <div class="spin">
    <div class="spin__wrapper">
      <span class="spin__el"></span>
      <span class="spin__el"></span>
      <span class="spin__el"></span>
      <span class="spin__el"></span>
    </div>
  </div>
</template>

<style scoped lang="less">
.spin {
  display: inline-flex;

  &__wrapper {
    position: relative;
    transform: rotate(45deg);
    animation: spinRotate 1.2s infinite linear;
    width: 32px;
    height: 32px;
  }

  &__el {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--accent-color);
    position: absolute;
    display: block;
    opacity: 0.5;
    animation: spinMove 1s infinite linear alternate;
    transform-origin: center center;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      right: 0;
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      right: 0;
      bottom: 0;
      animation-delay: 0.6s;
    }

    &:last-child {
      left: 0;
      bottom: 0;
      animation-delay: 0.9s;
    }
  }
}

@keyframes spinRotate {
  100% {
    transform: rotate(405deg);
  }
}

@keyframes spinMove {
  100% {
    opacity: 1;
  }
}
</style>
