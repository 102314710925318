<template>
  <textarea
    :class="['pax-textarea', themeStyles?.base?.textarea?.textarea]"
    :value="modelValue"
    @input="onInput"
    @blur="onBlur"
  ></textarea>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

export interface TextareaProps {
  modelValue: string;
}

interface Emits {
  (e: 'update:modelValue', value: string): void;

  (e: 'blur', value: FocusEvent): void;
}

defineProps<TextareaProps>();
const emits = defineEmits<Emits>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const onInput = (e: Event) => {
  const value = (e.target as HTMLTextAreaElement).value;
  emits('update:modelValue', value);
};

const onBlur = (e: FocusEvent) => {
  emits('blur', e);
};
</script>

<style lang="less" scoped>
.pax-textarea {
  color: var(--primary-color);
  border: var(--textarea-border);
  min-height: 140px;
  transition:
    all 0.3s,
    height 0s;
  padding: 16px 24px;
  resize: vertical;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    border-color: var(--textarea-hovered-color);
  }

  &::placeholder {
    color: var(--textarea-placeholder-color);
  }
}
</style>
