<template>
  <label :class="['pax-radio', { checked: modelValue === value }, themeStyles?.base?.radio?.radio]">
    <input v-model="model" type="radio" :value="value" class="pax-radio__input" />

    <span class="pax-radio__icon"></span>
    <span class="pax-radio__title">
      <slot />
    </span>
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

type RadioValueType = string | null | number;

interface PaxRadioProps {
  modelValue: RadioValueType;
  value: RadioValueType;
}

interface Emits {
  (e: 'update:modelValue', value: RadioValueType): void;
}

const model = computed<RadioValueType>({
  get() {
    return props.modelValue;
  },
  set(val: RadioValueType) {
    emits('update:modelValue', val);
  }
});

const props = defineProps<PaxRadioProps>();
const emits = defineEmits<Emits>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style lang="less" scoped>
.pax-radio {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  color: var(--primary-color);

  &__title {
    margin-left: 8px;
    line-height: 23px;
  }

  &__icon {
    height: var(--radio-size);
    width: var(--radio-size);
    background: transparent;
    border: var(--radio-border);
    border-radius: 50%;
    transition: all 0.3s;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--radio-checked-color);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.15s ease-in;
    }
  }

  &__input {
    opacity: 0;
    outline: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1;

    &:focus + .pax-radio__icon {
      border-color: var(--radio-hovered-color);
    }
  }

  &.checked {
    .pax-radio__icon:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &:hover &__icon {
    border-color: var(--radio-hovered-color);
  }
}
</style>
