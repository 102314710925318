import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "pax-checkbox__box" }
const _hoisted_3 = { class: "pax-checkbox__title" }

import { computed } from 'vue';
import Check from '@/components/icons/check.vue';
import { useTheme } from '@/themes/hooks';

// работает только с boolean
interface PaxCheckboxProps {
  modelValue: boolean;
  disabled?: boolean;
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaxCheckbox',
  props: {
    modelValue: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const model = computed({
  get() {
    return props.modelValue;
  },

  set(val: boolean) {
    emits('update:modelValue', val);
  }
});

const isChecked = computed(() => {
  return props.modelValue;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['pax-checkbox', { checked: isChecked.value }, themeStyles.value?.base?.checkbox?.checkbox])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      type: "checkbox",
      class: "pax-checkbox__input",
      disabled: _ctx.disabled
    }, null, 8, _hoisted_1), [
      [_vModelCheckbox, model.value]
    ]),
    _createElementVNode("span", _hoisted_2, [
      (isChecked.value)
        ? (_openBlock(), _createBlock(Check, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})