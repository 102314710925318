<template>
  <div class="lang-selector">
    <span :class="{ 'lang-selector__selected-item': true, clickable: isClickable }" @click="toggleVisible">
      <img :src="getLangIconUrl(selectedLanguage.locale)" class="lang-selector__item-icon" />
    </span>

    <transition name="fade">
      <div v-show="visible" class="lang-selector__content">
        <span class="lang-selector__content-arrow"></span>
        <div class="lang-selector__content-inner">
          <div
            v-for="lang in languages"
            :key="lang.locale"
            :class="['lang-selector__item', { selected: lang.locale === selectedLanguage.locale }]"
            @click="() => onItemClick(lang)"
          >
            <img :src="getLangIconUrl(lang.locale)" class="lang-selector__item-icon" alt="" />
            {{ lang.label }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { Language } from '@/types';
import { ref, computed } from 'vue';

interface Props {
  selectedLanguage: Language;
  languages: Language[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:selectedLanguage', selectedLanguage: Language): void;
}>();

const visible = ref(false);

function onItemClick(language: Language) {
  visible.value = false;
  emit('update:selectedLanguage', language);
}

function getLangIconUrl(locale: string) {
  return `https://cdn.websky.aero/content/frontend/images/locales-flags/${locale}.svg`;
}

const isClickable = computed(() => {
  return props.languages.length > 1;
});

function toggleVisible() {
  if (isClickable.value) {
    visible.value = !visible.value;
  }
}
</script>

<style scoped lang="less">
.lang-selector {
  position: relative;

  &__selected-item {
    &.clickable {
      cursor: pointer;
    }
  }

  &__item {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 42px;

    &.selected,
    &:hover {
      color: var(--accent-color);
    }

    &-icon {
      width: 32px;
      height: 24px;
      border-radius: 4px;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    &-arrow {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }

    &-inner {
      padding: 10px 20px;
      background-color: #fff;
      box-shadow: 10px 10px 44px 0 rgba(0, 0, 0, 0.14);
      border-radius: 4px;
    }

    @media screen and (max-width: 1230px) {
      left: auto;
      right: 0;
      transform: none;

      &-arrow {
        left: auto;
        right: 5px;
      }
    }
  }
}
</style>
