import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content'])
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__header'])
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__img'])
}

import CompleteImg from '@/components/images/Complete.vue';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Complete',
  setup(__props) {

const { theme } = useTheme();
const { t } = useI18n();

const completeStyles = computed(() => theme?.value?.styles?.complete);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['complete', completeStyles.value?.complete])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass(['complete__content__header__title', completeStyles.value?.title])
        }, _toDisplayString(_unref(t)('Thank you')), 3),
        _createElementVNode("span", {
          class: _normalizeClass(['complete__content__header__description', completeStyles.value?.description])
        }, _toDisplayString(_unref(t)('You helped us a lot')), 3)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_unref(theme)?.images?.complete)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).images.complete), { key: 0 }))
          : (_openBlock(), _createBlock(CompleteImg, { key: 1 }))
      ])
    ])
  ], 2))
}
}

})