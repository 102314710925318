import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { PaxTextarea } from '@/components';
import { computed, ref, watch } from 'vue';
import { TextAnswerType, TextQuestionRes } from '@/api/response.types';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: TextQuestionRes;
}

type ValueType = TextAnswerType;


export default /*@__PURE__*/_defineComponent({
  __name: 'TextAnswer',
  props: {
    question: {}
  },
  emits: ["update:value", "update:validation"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { t } = useI18n();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const innerValue = ref<string>('');

const getNormalizeValue = () => {
  return innerValue.value.trim();
};
const onBlueTextarea = () => {
  const newValue = getNormalizeValue();
  if ((newValue?.length || !props.question.required) && newValue !== props.question.answer) {
    emit('update:value', newValue ?? (props.question.required ? null : ''));
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = props.question.answer ?? '';
  },
  { immediate: true }
);
watch(
  innerValue,
  () => {
    emit('update:validation', !!getNormalizeValue());
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(PaxTextarea), {
    "model-value": innerValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((innerValue).value = $event)),
    class: _normalizeClass([themeStyles.value?.answers?.textAnswer?.textAnser]),
    placeholder: _unref(t)('textareaPlaceHolder'),
    onBlur: onBlueTextarea
  }, null, 8, ["model-value", "class", "placeholder"]))
}
}

})