import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, provide, ref } from 'vue';
import { THEME_KEY } from '@/consts/common';
import { Theme } from '@/themes/types';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';
import { useTranslate } from '@/hooks/translates';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

provide(THEME_KEY, ref<Theme>());

useTranslate();
const { t } = useI18n();

useHead({
  title: computed(() => t('Survey'))
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})