<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70121 0.889982L3.92121 5.88998L2.29121 3.77998H2.29121C1.95155 3.34368 1.32251 3.26533 0.886207 3.60498C0.449903 3.94464 0.371553 4.57368 0.711208 5.00998V5.00998L3.14121 8.11998V8.11998C3.33203 8.36147 3.62343 8.50164 3.93121 8.49998H3.93121C4.2407 8.49924 4.53241 8.35523 4.72121 8.10998L9.29121 2.10998V2.10998C9.6281 1.66815 9.54304 1.03687 9.10121 0.699982C8.65938 0.363088 8.0281 0.448154 7.69121 0.889982C7.69121 0.889982 7.69121 0.889982 7.69121 0.889982L7.70121 0.889982Z"
      fill="currentColor"
    />
  </svg>
</template>
