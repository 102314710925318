<template>
  <div :class="['root', themeStyles?.app?.root]">
    <header :class="['header', themeStyles?.header?.header]">
      <div class="header-content">
        <span :class="['header-icon', themeStyles?.header?.icon]">
          <template v-if="theme?.icons?.logo">
            <component :is="theme.icons.logo" />
          </template>

          <template v-else>
            <logo-icon />
          </template>
        </span>

        <lang-selector
          v-if="selectedLanguage"
          v-model:selected-language="selectedLanguage"
          :languages="allowLanguages"
        />
      </div>
    </header>

    <pax-progress v-if="!(error || isComplete || isFirstLoading)" :percent="progress" class="progress-bar" />

    <main class="content">
      <transition name="fade" mode="out-in">
        <error-view v-if="error" :error="error" />
        <complete-view v-else-if="isComplete" />

        <questions-view
          v-else-if="surveyModel"
          :questions="surveyModel.questions ?? []"
          :is-loading="isLoading"
          @update:answer="updateAnswer"
          @send="sendComplete"
          @progress="(value) => (progress = value)"
        />

        <question-skeleton v-else />
      </transition>
    </main>
  </div>
  <pax-spin v-if="isLoading && surveyModel" class="loader" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';
import LogoIcon from '@/components/icons/logo.vue';
import QuestionsView from '@/components/questions/Questions.vue';
import QuestionSkeleton from '@/components/questions/Question.skeleton.vue';
import CompleteView from '@/components/Complete.vue';
import ErrorView from '@/components/Error.vue';
import { useSurvey } from '@/hooks/survey';
import LangSelector from '@/components/LangSelector/LangSelector.vue';
import { PaxProgress, Spin as PaxSpin } from '@/components';

const { theme, switchTheme } = useTheme();
switchTheme();
const themeStyles = computed(() => theme.value?.styles);

const {
  surveyModel,
  isLoading,
  isFirstLoading,
  error,
  progress,
  isComplete,
  updateAnswer,
  sendComplete,
  allowLanguages,
  selectedLanguage
} = useSurvey();
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.root {
  background: var(--canvas-color);
}

.header {
  background: var(--header-color);
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  display: flex;
  padding: 0;
  z-index: 1;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--question-item-width-1920);

    margin: 0 auto;
    padding: var(--header-padding);
  }

  &-icon {
    display: flex;

    & > svg {
      width: 244px;
      height: 44px;
    }

    @media @size375 {
      & > svg {
        width: 162px;
        height: 29px;
      }
    }
  }
}

.progress-bar {
  top: var(--header-height);
}

.content {
  width: 100%;
  margin-top: calc(var(--header-height) + var(--progress-bg-width));
}

.loader {
  position: fixed;
  top: 50vh;
  left: 50vw;
}
</style>
