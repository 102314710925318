<template>
  <div class="error">
    <div class="error__content">
      <div class="message">
        <span class="message__title">
          {{ isCompleted ? t('CompletedTitle') : t('ErrorTitle') }}
        </span>

        <span v-if="!isCompleted" class="message__text">{{ t('ErrorText') }}</span>
        <span v-if="error" class="message__error">{{ error }}</span>
      </div>

      <div :class="['img']">
        <template v-if="theme?.images?.error">
          <component :is="theme.images.error" />
        </template>
        <template v-else>
          <error-img class="img-base" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from '@/themes/hooks';
import ErrorImg from '@/components/images/Error.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const COMPLETED_SURVEY_ERROR_TEXT = 'The survey has already been completed';

interface Props {
  error?: string;
}

const props = defineProps<Props>();

const { theme } = useTheme();
const { t } = useI18n();

const isCompleted = computed(() => props.error === COMPLETED_SURVEY_ERROR_TEXT);
</script>

<style scoped lang="less">
.error {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--question-item-bg-color);
  padding-top: 70px;
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;
    max-width: var(--question-item-width-1920);
    margin: 0 auto;
    padding: 0 var(--page-padding);
    width: 100%;
    justify-content: space-between;

    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--primary-color);
      font-weight: var(--error-text-font-weight);

      &__title {
        font-size: 59px;
        font-weight: 600;
        line-height: 1.1;
        letter-spacing: -0.01em;
      }

      &__text {
        font-size: 41px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: -0.01em;
      }

      &__error {
        font-weight: 400;
        font-size: 14px;
        margin-top: 30px;
      }
    }

    .img {
      display: flex;

      &-base {
        color: var(--primary-color);
      }
    }
  }
}
</style>
