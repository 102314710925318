<template>
  <div class="skeleton-item"></div>
</template>

<script setup lang="ts"></script>
<style lang="css" scoped>
.skeleton-item {
  --skeleton-color: #dee0e4;
  --skeleton-color-darker: #c5c8cc;

  height: 32px;
  border-radius: 6px;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--skeleton-color) 25%,
    var(--skeleton-color-darker) 37%,
    var(--skeleton-color) 63%
  );
  background-size: 400% 100%;
  animation: loading 1.4s ease infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
