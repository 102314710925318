import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { loadLocaleMessages } from '@/i18n';
import { LocaleValue } from '@/i18n/i18n.types';
import { getThemeName } from '@/utils/common';

export const useTranslate = () => {
  const i18n = useI18n();
  const themeName = getThemeName();

  const isExists = (local: LocaleValue) => {
    return i18n.messages.value[local] !== undefined;
  };

  function isLoaded(local: string) {
    const messages = i18n.messages.value[local];

    if (!messages) {
      return false;
    }

    return Object.keys(messages).length > 0;
  }

  const loaded = computed(() => isLoaded(i18n.locale.value));

  const setComponentTranslates = async (newLocal: LocaleValue) => {
    const messages = (await loadLocaleMessages(newLocal, themeName)) ?? {};
    i18n.mergeLocaleMessage(newLocal, messages);
  };

  watch(
    i18n.locale,
    async (newLocal) => {
      if (!isExists(newLocal) || !loaded.value) {
        await setComponentTranslates(newLocal);
      }
    },
    { immediate: true }
  );
};
