<template>
  <div :class="['check-answer', themeStyles?.answers?.checkAnswer?.checkAnswer]">
    <div class="check-answer__list">
      <pax-checkbox
        v-for="option in question.options"
        :key="option.value"
        :model-value="optionChecked(option)"
        @update:model-value="(value) => onUpdateChecked(option, value)"
      >
        {{ option.label }}
      </pax-checkbox>
    </div>
    <div class="check-answer__custom">
      <pax-textarea
        v-if="optionChecked(customOption)"
        :id="customValueElId"
        v-model:model-value="innerCustomValue"
        :class="themeStyles?.answers?.checkAnswer?.customValue"
        :placeholder="t('textareaPlaceHolder')"
        @blur="onBlueTextarea"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckAnswerType, CheckQuestionRes, QuestionOption } from '@/api/response.types';
import { computed, nextTick, ref, watch } from 'vue';
import { PaxCheckbox, PaxTextarea } from '@/components';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: CheckQuestionRes;
}

type ValueType = CheckAnswerType;

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: ValueType): void;
  (e: 'update:validation', valid: boolean): void;
}>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const { t } = useI18n();

const customValueElId = `customValue[${props.question.id}]`;
const staticOptions = computed(() => props.question.options.filter((opt) => !opt.custom));
const customOption = computed(() => {
  return props.question.options?.find((opt) => opt.custom);
});

const getCustomValue = (value: string[] | null) => {
  return value?.find((i) => staticOptions.value.every((opt) => opt.value !== i)) ?? customOption.value?.value ?? '';
};

const innerValue = ref<ValueType>(null);
const innerCustomValue = ref(getCustomValue(props.question.answer));

const getCustomNormalizeValue = () => {
  return innerCustomValue.value.trim();
};

const optionChecked = (option?: QuestionOption): boolean => {
  if (!option || innerValue.value === null) {
    return false;
  }

  if (option.custom) {
    return innerValue.value.some((i) => staticOptions.value.every((opt) => opt.value !== i));
  }

  return innerValue.value.includes(option.value);
};

const onUpdateChecked = (option: QuestionOption, checked: boolean) => {
  let newQuestionValue: ValueType;
  if (checked) {
    newQuestionValue = innerValue.value ? [...innerValue.value] : [];
    newQuestionValue.push(option.custom ? getCustomNormalizeValue() : option.value);
  } else {
    const targetValue = option.custom ? getCustomNormalizeValue() : option.value;
    newQuestionValue = innerValue.value!.filter((i) => i !== targetValue);
  }

  if (option.custom) {
    nextTick(() => {
      document.getElementById(customValueElId)?.focus();
    });
  }

  if (newQuestionValue.length && (!option.custom || innerCustomValue.value.length)) {
    emit('update:value', newQuestionValue);
  }

  if (option.custom) {
    emit('update:validation', !!innerCustomValue.value.length);
  }

  innerValue.value = newQuestionValue;
};

const onBlueTextarea = () => {
  const customNormalizeValue = getCustomNormalizeValue();
  if (
    customNormalizeValue.length &&
    customNormalizeValue !== getCustomValue(props.question.answer) &&
    innerValue.value?.length
  ) {
    const notCustomOptionValues: Set<string> = new Set(
      props.question.options.filter((opt) => !opt.custom).map((opt) => opt.value)
    );
    const newValue = innerValue.value?.map((v) => (notCustomOptionValues.has(v) ? v : customNormalizeValue)) ?? [
      customNormalizeValue
    ];

    emit('update:value', newValue);
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = props.question.answer;
  },
  { immediate: true }
);
watch(
  innerValue,
  (value) => {
    const isValid = !!value?.length && value.every((v) => v.length);
    emit('update:validation', isValid);
  },
  { immediate: true }
);
watch(innerCustomValue, (newValue, oldValue) => {
  innerValue.value = innerValue.value?.map((v) => (v === oldValue ? getCustomNormalizeValue() : v)) ?? null;
});
</script>

<style scoped lang="less">
.check-answer {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__custom {
    margin: 10px 0 0 calc(var(--checkbox-size) + 8px);
  }
}
</style>
