<template>
  <router-view />
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import { THEME_KEY } from '@/consts/common';
import { Theme } from '@/themes/types';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';
import { useTranslate } from '@/hooks/translates';

provide(THEME_KEY, ref<Theme>());

useTranslate();
const { t } = useI18n();

useHead({
  title: computed(() => t('Survey'))
});
</script>
