import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

export interface TextareaProps {
  modelValue: string;
}

interface Emits {
  (e: 'update:modelValue', value: string): void;

  (e: 'blur', value: FocusEvent): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaxTextarea',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue", "blur"],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const onInput = (e: Event) => {
  const value = (e.target as HTMLTextAreaElement).value;
  emits('update:modelValue', value);
};

const onBlur = (e: FocusEvent) => {
  emits('blur', e);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("textarea", {
    class: _normalizeClass(['pax-textarea', themeStyles.value?.base?.textarea?.textarea]),
    value: _ctx.modelValue,
    onInput: onInput,
    onBlur: onBlur
  }, null, 42, _hoisted_1))
}
}

})