<template>
  <pax-textarea
    v-model:model-value="innerValue"
    :class="[themeStyles?.answers?.textAnswer?.textAnser]"
    :placeholder="t('textareaPlaceHolder')"
    @blur="onBlueTextarea"
  />
</template>

<script setup lang="ts">
import { PaxTextarea } from '@/components';
import { computed, ref, watch } from 'vue';
import { TextAnswerType, TextQuestionRes } from '@/api/response.types';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: TextQuestionRes;
}

type ValueType = TextAnswerType;

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: ValueType): void;
  (e: 'update:validation', valid: boolean): void;
}>();

const { t } = useI18n();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const innerValue = ref<string>('');

const getNormalizeValue = () => {
  return innerValue.value.trim();
};
const onBlueTextarea = () => {
  const newValue = getNormalizeValue();
  if ((newValue?.length || !props.question.required) && newValue !== props.question.answer) {
    emit('update:value', newValue ?? (props.question.required ? null : ''));
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = props.question.answer ?? '';
  },
  { immediate: true }
);
watch(
  innerValue,
  () => {
    emit('update:validation', !!getNormalizeValue());
  },
  { immediate: true }
);
</script>
