<template>
  <label :class="['pax-checkbox', { checked: isChecked }, themeStyles?.base?.checkbox?.checkbox]">
    <input v-model="model" type="checkbox" class="pax-checkbox__input" :disabled="disabled" />
    <span class="pax-checkbox__box">
      <check v-if="isChecked" />
    </span>

    <span class="pax-checkbox__title">
      <slot />
    </span>
  </label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Check from '@/components/icons/check.vue';
import { useTheme } from '@/themes/hooks';

// работает только с boolean
interface PaxCheckboxProps {
  modelValue: boolean;
  disabled?: boolean;
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void;
}

const props = defineProps<PaxCheckboxProps>();
const emits = defineEmits<Emits>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const model = computed({
  get() {
    return props.modelValue;
  },

  set(val: boolean) {
    emits('update:modelValue', val);
  }
});

const isChecked = computed(() => {
  return props.modelValue;
});
</script>

<style scoped lang="less">
.pax-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  color: var(--primary-color);

  &__title {
    line-height: 23px;
  }

  &__input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  &__box {
    border: var(--checkbox-border);
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background-color: transparent;
    border-radius: var(--checkbox-border-radius);
    color: var(--checkbox-checked-color);
    position: relative;
    display: flex;
    transition: all 0.3s;
    flex-shrink: 0;

    svg {
      margin: auto;
    }
  }

  &:focus &__box,
  &:hover &__box {
    border-color: var(--checkbox-hovered-color);
    color: var(--checkbox-hovered-color);
  }
}
</style>
