<template>
  <div ref="questionsRef" :class="['questions', themeStyles?.questions?.questions]">
    <div
      v-for="(question, idx) in visibleQuestions"
      :id="question.id"
      :key="question.id"
      :class="['question-item-wrapper']"
    >
      <div :class="['question-item', questionStyles?.item]">
        <span :class="['question-item__number', questionStyles?.number]">
          {{ t('question', { idx: questionIdx.get(question.id) }) }}
        </span>
        <span :class="['question-item__title', questionStyles?.title]">{{ question.label }}</span>
        <span v-if="question.description?.length" :class="['question-item__description', questionStyles?.description]">
          {{ question.description }}
        </span>

        <template v-if="question.type === 'Radio'">
          <radio-answer
            :question="question"
            :class="['question-item__answer', questionStyles?.answer]"
            @update:value="(value) => onUpdateAnswer(question, value)"
            @update:validation="(valid) => onUpdateValidation(question, valid)"
          />
        </template>
        <template v-else-if="question.type === 'Checkbox'">
          <check-answer
            :question="question"
            :class="['question-item__answer', questionStyles?.answer]"
            @update:value="(value) => onUpdateAnswer(question, value)"
            @update:validation="(valid) => onUpdateValidation(question, valid)"
          />
        </template>
        <template v-else-if="question.type === 'Textarea'">
          <text-answer
            :question="question"
            :class="['question-item__answer', questionStyles?.answer]"
            @update:value="(value) => onUpdateAnswer(question, value)"
            @update:validation="(valid) => onUpdateValidation(question, valid)"
          />
        </template>

        <div v-if="idx === visibleQuestions.length - 1" class="question-item__actions">
          <pax-button
            v-if="visibleQuestions.length !== questions.length"
            :disabled="!isValid"
            @click="addNextVisibleQuestion"
          >
            {{ t('next') }}

            <template v-if="theme?.icons?.questions?.next">
              <component :is="theme.icons.questions.next" />
            </template>
            <template v-else>
              <right-arrow />
            </template>
          </pax-button>

          <pax-button
            v-else
            variant="primary"
            :disabled="!isValid"
            :class="['question-item__actions__send-action']"
            @click="emit('send')"
          >
            {{ t('send') }}
          </pax-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from '@/themes/hooks';
import { computed, ref, watch, nextTick } from 'vue';
import { RadioAnswer, CheckAnswer, TextAnswer, PaxButton } from '@/components';
import RightArrow from '@/components/icons/rightArrow.vue';
import { QuestionRes, AnswerType } from '@/api/response.types';
import { useI18n } from 'vue-i18n';

type AnswerValueType = AnswerType;

interface Props {
  questions: QuestionRes[];
  isLoading: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:answer', question: QuestionRes, value: AnswerValueType): void;
  (e: 'send'): void;
  (e: 'progress', value: number): void;
}>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
const questionStyles = computed(() => themeStyles?.value?.questions?.question);

const { t } = useI18n();

const questionsRef = ref<HTMLDivElement>();
const questionIdx = computed(() => {
  return new Map(props.questions.map((q, i) => [q.id, i + 1]));
});

const visibleQuestions = ref<QuestionRes[]>([]);
const isValid = ref(false);
const validation: Record<string, boolean> = {};

const scrollIntoLastQuestion = () => {
  nextTick(() => {
    questionsRef.value?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  });
};

const emitProgress = () => {
  const value = props.questions.length ? ((visibleQuestions.value.length - 1) / props.questions.length) * 100 : 0;

  emit('progress', value);
};

const addNextVisibleQuestion = () => {
  const nextQuestion = props.questions.find((q) => q.answer == null && !visibleQuestions.value.includes(q));

  if (nextQuestion && !visibleQuestions.value.includes(nextQuestion)) {
    visibleQuestions.value.push(nextQuestion);
    scrollIntoLastQuestion();
    emitProgress();
  }
};

const onUpdateAnswer = (question: QuestionRes, value: AnswerValueType) => {
  emit('update:answer', question, value);
};

const onUpdateValidation = (question: QuestionRes, valid: boolean) => {
  validation[question.id] = valid || !question.required;

  isValid.value = Object.values(validation).every((v) => v);
};

watch(
  () => props.questions,
  () => {
    const oldVisibleQuestionsLength = visibleQuestions.value.length;

    visibleQuestions.value = props.questions.filter((q) => q.answer !== null);

    if (visibleQuestions.value.length < oldVisibleQuestionsLength || visibleQuestions.value.length == 0) {
      addNextVisibleQuestion();
    }
    scrollIntoLastQuestion();

    emitProgress();
  },
  { immediate: true }
);

watch(questionsRef, () => scrollIntoLastQuestion());
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.questions {
  display: flex;
  flex-direction: column;
  gap: var(--questions-gap);
}

.question-item {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));
  max-width: var(--question-item-width-1920);

  margin: 0 auto;
  padding: var(--page-padding);

  &-wrapper {
    background: var(--question-item-bg-color);
  }

  &__number {
    font-size: var(--question-item-number-font-size);
    font-weight: var(--question-item-number-font-weight);
    line-height: 1.3;
  }

  &__title {
    margin-top: 10px;
    font-size: var(--question-item-title-font-size);
    font-weight: var(--question-item-title-font-weight);
    line-height: var(--question-item-title-line-height);
  }

  &__description {
    margin-top: 30px;
    font-family: var(--question-item-font-family);
    font-size: var(--question-description-font-size);
    line-height: var(--question-description-line-height);
  }

  :deep(&__answer) {
    margin-top: 50px;
    flex-wrap: wrap;

    & .pax-checkbox {
      font-size: var(--question-item-font-size);
      line-height: var(--question-item-line-height);
    }

    @media @size375 {
      flex-direction: column;
    }
  }

  &__actions {
    margin-top: 54px;

    &__send-action {
      font-size: 19px;
    }
  }
}
</style>
