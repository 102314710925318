import { defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "pax-radio__title" }

import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

type RadioValueType = string | null | number;

interface PaxRadioProps {
  modelValue: RadioValueType;
  value: RadioValueType;
}

interface Emits {
  (e: 'update:modelValue', value: RadioValueType): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaxRadio',
  props: {
    modelValue: {},
    value: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const model = computed<RadioValueType>({
  get() {
    return props.modelValue;
  },
  set(val: RadioValueType) {
    emits('update:modelValue', val);
  }
});

const props = __props;
const emits = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['pax-radio', { checked: _ctx.modelValue === _ctx.value }, themeStyles.value?.base?.radio?.radio])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      type: "radio",
      value: _ctx.value,
      class: "pax-radio__input"
    }, null, 8, _hoisted_1), [
      [_vModelRadio, model.value]
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pax-radio__icon" }, null, -1)),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})