import { themeMap } from '@/themes/consts';

export const debounce = (fn: (...args: unknown[]) => void, timeout = 500) => {
  let timer: number;

  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), timeout);
  };
};

export function insertDomainPrefix(origin: string, domainPrefix: string | undefined): string {
  const endOfProtocolIdx = origin.indexOf(':');
  return origin.slice(0, endOfProtocolIdx + 3) + (domainPrefix ?? '') + origin.slice(endOfProtocolIdx + 3);
}

export const getThemeName = () => {
  return themeMap.get(window.location.hostname);
};
