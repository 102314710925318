import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pax-progress" }
const _hoisted_2 = { class: "pax-progress__content" }
const _hoisted_3 = { class: "pax-progress__indicator" }

import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

interface Props {
  percent: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaxProgress',
  props: {
    percent: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "56d5b1b4": (progressWidthToPx.value),
  "1dee8e20": (indicatorPosition.value)
}))

const props = __props;

const contentPadding = ref(0);
const contentMaxWidth = ref(0);
const innerWidth = ref(0);

const progress = computed(() => props.percent / 100);

const contentStart = computed(() => {
  const paddings = contentPadding.value * 2;
  const contentWidth = Math.min(innerWidth.value - paddings, contentMaxWidth.value - paddings);

  // Смещение контента
  return (innerWidth.value - contentWidth) / 2;
});

const progressWidth = computed(() => {
  return contentStart.value + (innerWidth.value - contentStart.value) * progress.value;
});

const progressWidthToPx = computed(() => progressWidth.value + 'px');

const indicatorPosition = computed(() => {
  const rightOffset = 50;

  return Math.min(innerWidth.value - rightOffset, progressWidth.value) + 'px';
});

const updateWidth = () => {
  innerWidth.value = window.innerWidth;

  const bodyStyles = getComputedStyle(document.body);
  contentPadding.value = parseCSSValue(bodyStyles.getPropertyValue('--header-padding').split(' ')[1]);
};

const parseCSSValue = (value: string) => Number(value.replace('px', '').trim());

onMounted(() => {
  const bodyStyles = getComputedStyle(document.body);
  contentMaxWidth.value = parseCSSValue(bodyStyles.getPropertyValue('--question-item-width-1920'));

  updateWidth();
  window.addEventListener('resize', updateWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWidth);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pax-progress__value" }, null, -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.percent.toFixed() + '%'), 1)
    ])
  ]))
}
}

})