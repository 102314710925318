import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { logger } from '@/plugins';
import { setupI18n } from '@/i18n';
import { Logger } from './services';
import { createHead } from '@vueuse/head';

import './styles/breakpoints.less';
import './styles/variables.less';
import './styles/app.css';

const app = createApp(App);

const getDefaultLocale = () => {
  const urlParams = new URLSearchParams(window.location.hash.split('?')?.[1]);
  return urlParams.get('lang') ?? navigator.language;
};

const i18n = setupI18n({
  legacy: false,
  locale: getDefaultLocale(),
  fallbackLocale: 'en'
});

const isDevMode = process.env.NODE_ENV !== 'production';

export const loggerService = Logger.init(app, router, isDevMode);

app.use(router).use(i18n).use(logger, loggerService).use(createHead());

app.directive('focus', {
  mounted(el) {
    el.focus();
  }
});

app.mount('#app');
