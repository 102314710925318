import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "error" }
const _hoisted_2 = { class: "error__content" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "message__title" }
const _hoisted_5 = {
  key: 0,
  class: "message__text"
}
const _hoisted_6 = {
  key: 1,
  class: "message__error"
}
const _hoisted_7 = {
  class: /*@__PURE__*/_normalizeClass(['img'])
}

import { useTheme } from '@/themes/hooks';
import ErrorImg from '@/components/images/Error.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const COMPLETED_SURVEY_ERROR_TEXT = 'The survey has already been completed';

interface Props {
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Error',
  props: {
    error: {}
  },
  setup(__props: any) {

const props = __props;

const { theme } = useTheme();
const { t } = useI18n();

const isCompleted = computed(() => props.error === COMPLETED_SURVEY_ERROR_TEXT);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(isCompleted.value ? _unref(t)('CompletedTitle') : _unref(t)('ErrorTitle')), 1),
        (!isCompleted.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(t)('ErrorText')), 1))
          : _createCommentVNode("", true),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_unref(theme)?.images?.error)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).images.error), { key: 0 }))
          : (_openBlock(), _createBlock(ErrorImg, {
              key: 1,
              class: "img-base"
            }))
      ])
    ])
  ]))
}
}

})