<template>
  <button :disabled="disabled" :type="type" :class="['pax-button', variant, themeStyles?.base?.button?.button]">
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

type ButtonType = 'primary' | 'secondary';

interface ButtonProps {
  disabled?: HTMLButtonElement['disabled'];
  type?: HTMLButtonElement['type'];
  variant?: ButtonType;
}

withDefaults(defineProps<ButtonProps>(), {
  variant: 'secondary',
  type: 'button'
});

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style scoped lang="less">
.pax-button {
  padding: 16px 23px;
  border-radius: var(--button-border-radius);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  transition: all 0.3s ease;

  display: inline-flex;
  align-items: center;
  gap: 16px;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.secondary {
    background: var(--button-background);
    border: var(--button-border);
    color: var(--button-color);

    &:disabled {
      border-color: var(--button-disabled-background);
      background: var(--button-disabled-background);
      color: var(--button-disabled-color);
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      border-color: var(--button-hovered-background);
      background: var(--button-hovered-background);
    }
  }

  &.primary {
    background: var(--primaryButton-background);
    border: var(--primaryButton-border);
    color: var(--primaryButton-color);

    &:disabled {
      background: var(--primaryButton-disabled-background);
      color: var(--primaryButton-disabled-color);
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background: var(--primaryButton-hover-background);
    }
  }
}
</style>
