import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "type"]

import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

type ButtonType = 'primary' | 'secondary';

interface ButtonProps {
  disabled?: HTMLButtonElement['disabled'];
  type?: HTMLButtonElement['type'];
  variant?: ButtonType;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaxButton',
  props: {
    disabled: {},
    type: { default: 'button' },
    variant: { default: 'secondary' }
  },
  setup(__props: any) {



const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    type: _ctx.type,
    class: _normalizeClass(['pax-button', _ctx.variant, themeStyles.value?.base?.button?.button])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})