<template>
  <div class="pax-progress">
    <div class="pax-progress__content">
      <div class="pax-progress__value"></div>
      <div class="pax-progress__indicator">{{ percent.toFixed() + '%' }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

interface Props {
  percent: number;
}

const props = defineProps<Props>();

const contentPadding = ref(0);
const contentMaxWidth = ref(0);
const innerWidth = ref(0);

const progress = computed(() => props.percent / 100);

const contentStart = computed(() => {
  const paddings = contentPadding.value * 2;
  const contentWidth = Math.min(innerWidth.value - paddings, contentMaxWidth.value - paddings);

  // Смещение контента
  return (innerWidth.value - contentWidth) / 2;
});

const progressWidth = computed(() => {
  return contentStart.value + (innerWidth.value - contentStart.value) * progress.value;
});

const progressWidthToPx = computed(() => progressWidth.value + 'px');

const indicatorPosition = computed(() => {
  const rightOffset = 50;

  return Math.min(innerWidth.value - rightOffset, progressWidth.value) + 'px';
});

const updateWidth = () => {
  innerWidth.value = window.innerWidth;

  const bodyStyles = getComputedStyle(document.body);
  contentPadding.value = parseCSSValue(bodyStyles.getPropertyValue('--header-padding').split(' ')[1]);
};

const parseCSSValue = (value: string) => Number(value.replace('px', '').trim());

onMounted(() => {
  const bodyStyles = getComputedStyle(document.body);
  contentMaxWidth.value = parseCSSValue(bodyStyles.getPropertyValue('--question-item-width-1920'));

  updateWidth();
  window.addEventListener('resize', updateWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWidth);
});
</script>

<style lang="less" scoped>
.pax-progress {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: var(--progress-inner-color);
  color: var(--progress-inner-color);

  &__content {
    position: relative;
  }

  &__value {
    border-radius: var(--progress-bg-radius);
    background: var(--progress-bg-color);
    height: var(--progress-bg-width);
    transition: all 0.4s;
    position: absolute;
    left: 0;
    top: 0;
    width: v-bind(progressWidthToPx);
  }

  &__indicator {
    position: absolute;
    top: 14px;
    left: calc(v-bind(indicatorPosition) - 20px);
    background: var(--progress-bg-color);
    border-radius: 6px;
    padding: 2px 8px;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    min-width: 40px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 8px solid var(--progress-bg-color);
    }
  }
}
</style>
