import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "skeleton" }
const _hoisted_2 = { class: "skeleton__content" }

import { SkeletonItem } from '@/components';

export default /*@__PURE__*/_defineComponent({
  __name: 'Question.skeleton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(SkeletonItem), { class: "skeleton__content_item skeleton__content-item_1" }),
      _createVNode(_unref(SkeletonItem), { class: "skeleton__content_item skeleton__content-item_2" }),
      _createVNode(_unref(SkeletonItem), { class: "skeleton__content_item skeleton__content-item_3" }),
      _createVNode(_unref(SkeletonItem), { class: "skeleton__content_item skeleton__content-item_4" })
    ])
  ]))
}
}

})