<template>
  <div :class="['complete', completeStyles?.complete]">
    <div :class="['complete__content']">
      <div :class="['complete__content__header']">
        <span :class="['complete__content__header__title', completeStyles?.title]">
          {{ t('Thank you') }}
        </span>
        <span :class="['complete__content__header__description', completeStyles?.description]">
          {{ t('You helped us a lot') }}
        </span>
      </div>

      <div :class="['complete__content__img']">
        <template v-if="theme?.images?.complete">
          <component :is="theme.images.complete" />
        </template>
        <template v-else>
          <complete-img />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CompleteImg from '@/components/images/Complete.vue';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { theme } = useTheme();
const { t } = useI18n();

const completeStyles = computed(() => theme?.value?.styles?.complete);
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.complete {
  display: flex;
  align-items: center;
  background: var(--question-item-bg-color);
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: var(--page-padding);
    max-width: var(--question-item-width-1920);
    width: 100%;
    justify-content: space-between;
    gap: 20px;

    @media @size767 {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 707px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 500px;
      line-height: 1.3;

      &__title {
        font-size: 41px;
        font-weight: var(--complete-title-font-weight);
        color: var(--complete-title-color);
        max-width: 100%;

        @media @size375 {
          font-size: 34px;
        }
      }

      &__description {
        font-size: 23px;
        max-width: 100%;

        @media @size375 {
          font-size: 19px;
        }
      }
    }

    &__img {
      display: flex;

      & > svg {
        width: 100%;

        @media @size767 {
          height: auto;
        }
      }
    }
  }
}
</style>
